import React, { useState } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
const Login = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://lookatmein2.duckdns.org/api/user/login', { email: email, password });
      const token = response.data.token;
      // console.log('Login response:', token);
      localStorage.setItem('token', token);
      setToken(token);
      navigate('/main');
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleHelloClick = async () => {
    try {
      const response = await axios.get('https://lookatmein2.duckdns.org/api/user/test'); // Replace with the actual endpoint
      console.log('Hello response:', response.data);
    } catch (error) {
      console.error('Hello request failed', error);
    }
  };

  

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit">Login</button>
      <button type="button" onClick={handleHelloClick}>Hello</button>

    </form>
  );
};

export default Login;
