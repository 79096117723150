import React from 'react';
import axios from 'axios';

const MainPage = ({ token }) => {
  const handleHelloClick = async () => {
    try {
        // console.log('token', token);
      const response = await axios.get('https://lookatmein2.duckdns.org/user-service/test', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Hello response:', response.data);
    } catch (error) {
      console.error('Hello request failed', error);
    }
  };

  const autchCheck = async () => {
    try{
      const response = await axios.get("https://lookatmein2.duckdns.org/user-service/auth", {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Hello response:', response);
    }catch(error ){
      console.error('Hello request failed', error);
    }
  }

  return (
    <div>
      <button onClick={handleHelloClick}>Hello</button>
      <button onClick={autchCheck}>Auth</button>
    </div>
  );
};

export default MainPage;
